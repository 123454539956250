import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const HeroAgriPark = () => {

    const data = useStaticQuery(graphql`
        query {
            LogoAgripark: file(relativePath: { eq: "logo-agripark.svg" }) {
                publicURL
            },
        }
    `);

    return (
        <section>
            <div className="bg-agripark-orange">
                <div className="max-w-screen-lg py-10 mx-4 lg:m-auto">
                    <img
                        src={data.LogoAgripark.publicURL}
                        alt="Argipark - Agriscience, Research and Business Park"
                        height={70}
                        width={583} />
                </div>
            </div>
            <div className="lg:hidden">
                <StaticImage
                    placeholder="dominantColor"
                    src="../images/hero-agripark-grain-effect-mobile.png"
                    alt="AgriPark Hero Illustration" />
            </div>
            <div className="hidden w-full lg:block">
                <StaticImage
                    className="w-full"
                    src="../images/hero-agripark-grain-effect.png"
                    placeholder="dominantColor"
                    objectFit="cover"
                    alt="AgriPark Hero Illustration" />
            </div>
            <div className="max-w-screen-lg py-8 mx-4 lg:mx-auto ">
                <h2 className="text-3xl font-semibold">Housed at the regional innovation precinct</h2>
                <p className="w-4/5 mt-4 text-2xl font-normal text-gray-600 lg:text-3xl">
                    Anomaly resides on AgriPark, the innovation precinct in the heart of the Riverina dedicated to research,
                    curated collaboration and sustainable production.
                </p>
            </div>
            <div className="flex flex-col justify-between max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
                <div className="flex flex-col justify-between lg:w-2/5">
                    <p className="mb-10 text-lg text-gray-500">
                        Located on Charles Sturt University's Wagga Wagga campus, the AgriPark is a key step in the University's
                        long-held vision and commitment to support, facilitate and grow the nation's agricultural sector.
                    </p>
                    <a className="flex items-center text-lg text-anomaly-red" href="https://agripark.csu.edu.au/people/industry-partners">
                        Meet the industry partners
                        <ArrowRightIcon className="h-4 ml-2" />
                    </a>
                </div>
                <div className="flex flex-col justify-between mt-14 lg:mt-0 lg:w-2/5">
                    <p className="mb-10 text-lg text-gray-500">
                        We're committed to collaborating with AgriPark partners, providing opportunities for students and giving them access to our team.
                        In 2020 our principal was appointed as an adjunct professional.
                    </p>
                    <Link className="flex items-center text-lg text-anomaly-red" to="/about/agripark">
                        Learn about us at AgriPark
                        <ArrowRightIcon className="h-4 ml-2" />
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default HeroAgriPark;
