import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from 'gatsby'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const HeroMain = () => {

    const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark(limit: 1, sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 250)
              id
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                path
                description
                author
                category
              }
            }
          }
         }
      }      
    `);

    const { edges: posts } = data.allMarkdownRemark

    return (
        <section className="mx-auto lg:max-w-screen-xl">
            <div className="mx-8 mb-12 lg:mx-auto md:max-w-2xl">
                <h1 className="pb-1 mb-4 text-3xl font-semibold md:text-4xl background-aussie-flag">Anomaly is an all-Australian software engineering and design company.</h1>
                <p className="text-2xl text-gray-600 md:text-3xl">Our team builds technology solutions to empower human endeavour.</p>
            </div>
            <div className="lg:hidden">
                <StaticImage
                    src="../images/hero-fluid-grain-effect-mobile.png"
                    placeholder="dominantColor"
                    alt="Anomaly Hero" />
                {posts
                    .filter(post => post.node.frontmatter.title.length > 0)
                    .map(({ node: post }) => {
                        return (
                            <div key="feature-post" className="flex flex-row justify-between min-w-full p-8 mx-auto">
                                <Link to={post.frontmatter.path} className="flex flex-col text-xl">
                                    <span>{post.frontmatter.title}</span>
                                    <div className="flex items-center max-w-screen-lg text-xl text-anomaly-red">
                                        Read more
                                        <ArrowRightIcon className="h-4 ml-2" />
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
            </div>
            <div className="hidden lg:grid lg:max-w-screen-xl lg:mx-auto grid-rows-hero">
                <StaticImage
                    className="grid-rows-hero-image"
                    src="../images/hero-fluid-grain-effect.png"
                    placeholder="dominantColor"
                    alt="Anomaly Hero" />

                {posts
                    .filter(post => post.node.frontmatter.title.length > 0)
                    .map(({ node: post }) => {
                        return (
                            <div key="feature-post" className="flex flex-row justify-between min-w-full p-8 mx-auto bg-white rounded-lg shadow-xl grid-rows-blog-highlight">
                                <Link className="flex items-center justify-between w-full text-xl" to={post.frontmatter.path}>
                                    <span>{post.frontmatter.title}</span>
                                    <div className="flex items-center max-w-screen-lg text-xl text-anomaly-red">
                                        Read the post
                                        <ArrowRightIcon className="h-4 ml-2" />
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
            </div>
        </section>
    );
};

export default HeroMain;