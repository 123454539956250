import React from 'react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby"
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const FeatureLittleHelp = () => {
    const data = useStaticQuery(graphql`
        query {
            LogoStripe: file(relativePath: { eq: "logo-stripe.svg" }) {
                publicURL
            },
            LogoLinode: file(relativePath: { eq: "logo-linode.svg" }) {
                publicURL
            },
        }
    `);

    return (
        <div className="py-10 bg-white lg:py-20">
            <section className="flex flex-col justify-between max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
                <div className="flex flex-col justify-between lg:w-2/5">
                    <div className="grid grid-rows-[80px,1fr,auto]">
                        <img src={data.LogoStripe.publicURL} width="150" alt="Stripe Logo"/>
                        <h3 className="py-5 text-2xl font-semibold">Growing the Internet economy</h3>
                        <p className="mb-10 text-lg text-gray-500">
                            Stripe is a technology company that builds economic infrastructure for the internet. 
                            Their platform, which is highly developer-centric, greatly simplifies payment solutions of all kinds. 
                            Anomaly provides clients with Stripe integration services and uses their infrastructure for internal products.
                        </p>
                    </div>
                    <Link className="flex items-center text-lg text-anomaly-red" to="/capabilities/stripe">
                        View consulting packages
                        <ArrowRightIcon className="h-4 ml-2"/>
                    </Link>
                </div>
                <div className="flex flex-col justify-between mt-14 lg:mt-0 lg:w-2/5">
                    <div className="grid grid-rows-[80px,1fr,auto]">
                        <img src={data.LogoLinode.publicURL} width="200" alt="Linode Logo"/>
                        <h3 className="py-5 text-2xl font-semibold">On the alternate cloud</h3>
                        <p className="mb-10 text-lg text-gray-500">
                         Linode is the cloud developers trust. Their mission is to accelerate innovation by making cloud computing simple, affordable, 
                         and accessible to all. All Anomaly products either run on Linode or are deployable on their infrastructure.
                        </p>
                    </div>
                    <Link className="flex items-center text-lg text-anomaly-red" to="/capabilities/linode">
                        View our Linode offerings
                        <ArrowRightIcon className="h-4 ml-2"/>
                    </Link>
                </div>
            </section>
        </div>
    );
};

export default FeatureLittleHelp;