import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby';

const FeatureGetInTouch = () => {
    const data = useStaticQuery(graphql`
        query {
            BadgeStripeClimate: file(relativePath: { eq: "badge-stripe-climate.svg" }) {
                publicURL
            },
        }
    `);

    return (
        <section className="flex flex-col items-center py-10 mx-8 lg:mx-auto lg:max-w-screen-xl">
            <img src={data.BadgeStripeClimate.publicURL} className="w-16" alt="Stripe Climate"/>
            <p className="py-4 text-center">Anomaly will contribute <b>1% of your spend</b> to remove CO₂ from the atmosphere.</p>
            <a className="flex items-center text-lg text-anomaly-red" href="https://climate.stripe.com/0zMXQQ">
                Learn about Stripe Climate
                <svg className="w-4 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
            </a>

            <div className="flex flex-col max-w-screen-xl mx-auto mt-28">
                <p className="mb-8 text-2xl text-center">Every project starts with conversation.</p>
                <Link 
                    className="flex justify-center px-20 py-3 mx-auto border-2 rounded-md cursor-pointer text-anomaly-red border-anomaly-red hover:text-white hover:bg-anomaly-red" 
                    to="/contact">
                        Talk to us
                </Link>
            </div>


        </section>
    );
};

export default FeatureGetInTouch;
