import * as React from "react"
import Layout from "../components/Layout"
import HeroMain from "../components/HeroMain"
import HeroAgriPark from "../components/HeroAgripark"
import FeaturePortfolioSummary from '../components/FeaturePortfolioSummary'
import LittleHelp from "../components/FeatureLittleHelp"
import FeatureGetInTouch from "../components/FeatureGetInTouch"
import Seo from "../components/Seo"

const IndexPage = () => {
  const socialImage = {
    src: "/social.png",
    height: 2304,
    width: 4096
  }
  return (
    <Layout>
      <Seo 
        title="An all-Australian software engineering and design company" 
        description="Anomaly builds technology solutions to empower human endeavour."
        image={socialImage}
      />
      <HeroMain/>
      <FeaturePortfolioSummary/>
      <HeroAgriPark/>
      <div className="flex flex-col items-center max-w-screen-lg mx-auto mt-32 mb-16">
        <h2 className="text-2xl font-semibold text-center lg:text-3xl">With a little help from our friends</h2>
        <p className="pt-3 text-xl text-center text-gray-500 lg:text-3xl">Our partners help us scale what we build</p>
      </div>
      <LittleHelp/>
      <FeatureGetInTouch/>
    </Layout>
 );
};

export default IndexPage