import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const FeaturePortfolioSummary = () => {
    const data = useStaticQuery(graphql`
        query {
            LogoMoonRoom: file(relativePath: { eq: "logo-moonroom.png" }) {
                publicURL
            },
            LogoUC: file(relativePath: { eq: "logo-uc.png" }) {
                publicURL
            },
            LogoCSU: file(relativePath: { eq: "logo-csu.svg" }) {
                publicURL
            },
            LogoAudinate: file(relativePath: { eq: "logo-audinate.svg" }) {
                publicURL
            },
            LogoG1G: file(relativePath: { eq: "logo-g1g.svg" }) {
                publicURL
            },
            LogoWisetech: file(relativePath: { eq: "logo-wisetech-global.svg" }) {
                publicURL
            },
            LogoValen: file(relativePath: { eq: "logo-valen.svg" }) {
                publicURL
            },
            LogoWCC: file(relativePath: { eq: "logo-wcclp.svg" }) {
                publicURL
            },
            LogoTemoraShire: file(relativePath: { eq: "logo-temora-shire.svg" }) {
                publicURL
            },
        }
    `);

    return (
        <div className="grid items-center max-w-screen-lg grid-cols-2 gap-12 mx-8 my-10 lg:my-20 lg:mx-auto lg:gap-6 lg:grid-cols-4 justify-items-center">
            <img src={data.LogoUC.publicURL} width="190" alt="University of Canberra"/>
            <img src={data.LogoAudinate.publicURL} width="180" alt="Audinate"/>
            <img src={data.LogoWisetech.publicURL} width="170" alt="Wisetech Global"/>
            <img src={data.LogoCSU.publicURL} width="186" alt="Charles Sturt University"/>
            <img src={data.LogoTemoraShire.publicURL} width="140" alt="Temora Shire Council"/>
            <img src={data.LogoWCC.publicURL} width="62" alt="Wiradjuri Condobolin Corporation Language Program"/>
            <img src={data.LogoG1G.publicURL} width="192" alt="G1 Goldmine"/>
            <img src={data.LogoMoonRoom.publicURL} width="170" alt="MoonRoom"/>
        </div>
    );
};

export default FeaturePortfolioSummary;